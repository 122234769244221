exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-all-tsx": () => import("./../../../src/pages/blog/all.tsx" /* webpackChunkName: "component---src-pages-blog-all-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-templates-tsx": () => import("./../../../src/pages/templates.tsx" /* webpackChunkName: "component---src-pages-templates-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-changelog-post-tsx": () => import("./../../../src/templates/changelogPost.tsx" /* webpackChunkName: "component---src-templates-changelog-post-tsx" */),
  "component---src-templates-design-file-tsx": () => import("./../../../src/templates/designFile.tsx" /* webpackChunkName: "component---src-templates-design-file-tsx" */),
  "component---src-templates-profile-tsx": () => import("./../../../src/templates/profile.tsx" /* webpackChunkName: "component---src-templates-profile-tsx" */)
}

